<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Assets</span>
          <v-spacer></v-spacer>
          <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
            <v-select
              v-model="Fcategory"
              :items="catList"
              outlined
              item-text="name"
              item-value="_id"
              dense
              hide-details
              class="rounded-xl"
              label="Category"
              clearable
            >
            </v-select>
          </v-flex>
          <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
            <v-select
              v-model="Ftype"
              :items="typeList"
              outlined
              dense
              hide-details
              class="rounded-xl"
              label="Type"
              clearable
            >
            </v-select>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start v-if="assetList.length > 0">
          <v-flex
            xs12
            md6
            sm6
            lg4
            xl3
            align-self-end
            v-for="(item, i) in assetList"
            :key="i"
            fill-height
            pa-4
          >
            <v-card tile color="#F5F5DC">
              <v-layout wrap justify-end>
                
                <v-flex xs12 align-self-start text-right px-4 pa-1 >
                  <span v-if=" item.notAssignedStock && item.notAssignedStock<item.warning_minStock" class="px-2"  style="border:1px solid red;color:red;font-family: kumbhMedium"
                    >Stock has reached minimum limit</span
                  >
                  <!-- <span v-else-if="item.notAssignedStock" class="px-2"  style="border:1px solid green;color:green;font-family: kumbhMedium">
                    Stock available
                  </span>
                  <span v-else style="color:#F5F5DC">
                    N/A</span>   -->
                </v-flex>
                <v-flex xs12 align-self-start text-center pa-4>
                  <span class="lheight" style="font-family: kumbhBold">{{
                    item.name
                  }}</span>
                </v-flex>
                <v-flex xs12 align-self-start text-left px-4 pa-1>
                  <span  style="font-family: kumbhBold"
                    >Total Stock : {{ item.totalStock }}</span
                  >
                </v-flex>
                <v-flex xs12 align-self-start text-left px-4 pa-1>
                  <span  style="font-family: kumbhBold"
                    >Stock Not-assigned : {{ item.notAssignedStock }}</span
                  >
                </v-flex>
                <v-flex xs12 align-self-center>
                  <v-divider></v-divider>
                </v-flex>

                <!-- <v-flex xs6 sm4 align-self-center md2 pa-2>
                  <v-dialog persistent v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: kumbhBold"
                        class="mr-2"
                        fab
                        outlined
                        depressed
                        dark
                        title="Delete"
                        v-on="on"
                        v-bind="attrs"
                        color="#005f32"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          align-self-center
                          pa-4
                          style="background: #005f32"
                          text-left
                        >
                          <span class="kumbhBold" style="color: #ffffff"
                            >Delete Confirmation</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-card-title
                        >Are you sure you want to delete this Asset
                        category?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          tile
                          dark
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        &nbsp;
                        <v-btn
                          color="#005f32"
                          outlined
                          tile
                          @click="deleteItem(item)"
                          >Delete</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex> -->
                <v-flex xs6 sm4 md2 pa-2 align-self-center>
                  <v-btn
                    color="#005f32"
                    style="font-family: kumbhBold"
                    small
                    dark
                    outlined
                    tile
                    title="View"
                    depressed
                    :to="
                      '/Assets/ViewLog?id=' + item._id + '&name=' + item.name
                    "
                  >
                    <span>View log</span>
                  </v-btn>
                </v-flex>
                <v-flex>
                  <v-spacer></v-spacer>
                </v-flex>

                <v-flex xs6 sm4 md2 pa-2 align-self-center>
                  <v-btn
                    color="#005f32"
                    style="font-family: kumbhBold"
                    small
                    dark
                    outlined
                    title="View"
                    depressed
                    fab
                    :to="'/Assets/ViewAsset?id=' + item._id"
                  >
                    <v-icon color="#005f32">mdi-eye</v-icon>
                  </v-btn>
                </v-flex>

                <v-flex xs6 sm4 md2 pa-2 align-self-center>
                  <v-btn
                    color="#005f32"
                    style="font-family: kumbhBold"
                    small
                    dark
                    outlined
                    title="Edit"
                    depressed
                    fab
                    :to="'/Assets/EditAsset?id=' + item._id"
                  >
                    <v-icon color="#005f32">mdi-pen</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Assets found!</span>
          </v-flex>
        </v-layout>

        <v-dialog persistent v-model="editdialog" max-width="40%">
          <v-card tile>
            <v-layout wrap>
              <v-flex
                xs12
                pa-4
                text-left
                align-self-center
                style="background: #005f32 !important"
              >
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
                  >Edit Category</span
                >
              </v-flex>
              <v-flex xs12 pa-5 pt-8 text-left>
                <v-text-field
                  v-model="editingitem.name"
                  label="Category Name"
                  :rules="[rules.required]"
                  outlined
                  autofocus
                  dense
                  required
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                tile
                outlined
                @click="(editdialog = false), getData()"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                v-if="!g"
                color="#005f32"
                dark
                tile
                @click="edit(editingitem._id)"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      assetList: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      name: null,
      catList: null,
      Fcategory: null,
      Ftype: null,
      typeList: ["Consumable", "Nonconsumable", "Semiconsumable"],
    };
  },
  components: {
    // ImageComp,
  },
  watch: {
    Fcategory() {
      this.currentPage = 1;
      this.getData();
    },
    Ftype() {
      this.currentPage = 1;
      this.getData();
    },

    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.getCategory();
    this.getData();
  },

  methods: {
    getCategory() {
      this.appLoading = true;
      axios({
        url: "/assetCategory/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.catList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/assets/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          assetCategoryId: this.Fcategory,
          assetType: this.Ftype,
          page: this.currentPage,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.assetList = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/assetCategory/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.getData();
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
